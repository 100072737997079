section.text
{
  padding-top: 53px;
  font-weight: 300;
  font-size: 14px;

  h2
  {
    position: relative;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 29px;
    margin-left: 16px;
    margin-bottom: 18px;
  }
}