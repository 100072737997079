footer
{
  color: #565656;
  font-size: 14px;
  line-height: 1.2em;
  padding: 25px 0;
  background-color: $bg-primary;

  .facebook
  {
    display: inline-block;
    margin-top: 8px;
  }
}