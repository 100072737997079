.realizations
{
  padding-top: 53px;

  h2
  {
    position: relative;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 29px;
    margin-left: 16px;
    margin-bottom: 18px;
  }

  &-item
  {
    padding: 0 15px;
    text-align: center;
    margin-bottom: 25px;

    @media (min-width: $md)
    {
      width: 25%;
      float: left;
    }

    &-image
    {
      height: 165px;
      overflow: hidden;
    }

    a
    {
      text-decoration: none;

      &:hover
      {
        text-decoration: none;
      }

      p
      {
        font-size: 18px;
        margin-top: 6px;
        height: 54px;
        overflow: hidden;
      }
    }
  }
}

.realization
{
  margin-top: 53px;

  h2
  {
    padding-left: 15px;
    margin-bottom: 15px;
    font-weight: 300;
  }

  &-gallery
  {
    &-item
    {
      padding: 0 15px;
      margin-bottom: 30px;

      @media (min-width: $md)
      {
        float: left;
        width: 25%;
      }
    }
  }

  &-back
  {
    font-size: 18px;
    font-weight: 300;
  }
}