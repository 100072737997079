.banner
{
  width: 100%;
  overflow: hidden;

  .item
  {
    position: relative;

    @media (max-width: 480px)
    {
      height: 325px;
      width: 700px;
      left: 50%;
      margin-left: -350px;
    }

    img
    {
      position: relative;
      z-index: 1;
    }

    p
    {
      margin-bottom: 0;
    }

    @media (min-width: $md)
    {
      p
      {
        margin-bottom: 1rem;
      }
    }

    &-content
    {
      position: absolute;
      bottom: 0;
      z-index: 2;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .btn-black
      {
        display: inline-block;
        background-color: #000;
        color: #fff;
        padding: 15px 30px;
        text-transform: uppercase;
        border-radius: 30px;
        font-size: 18px;
        font-weight: 300;
        min-width: 290px;
        text-align: center;
        margin-bottom: 5px;

        @media (max-width: 480px)
        {
          display: block;
        }

        @media (min-width: $md)
        {
          margin-left: 90px;
          margin-bottom: 0;

          &:first-child
          {
            margin-left: 0;
          }
        }

        &:hover
        {
          text-decoration: none;
        }
      }
    }
  }

  &-list
  {
    padding: 20px 0;
    background-color: #111111;

    h2
    {
      border-bottom: 1px solid #292929;
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 300;
      padding: 0 0 15px;
      text-transform: uppercase;
      color: #fff;
    }

    .owl-carousel
    {
      .item
      {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 72px;

        a
        {
          display: block;
        }

        img
        {
          width: auto;
          display: inline-block;
        }
      }
    }
  }

  .owl-carousel
  {
    .owl-item.active
    {
      z-index: 99999;
    }
  }
}