body
{
  background-color: $bg-primary;
  min-height: 100vh;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

a
{
  color: $color-link;

  &:hover,
  &:focus
  {
    color: $color-link;
  }
}

.mt-80px {margin-top: 80px}
.mt-10px {margin-top: 10px}

.pall-15px {padding: 15px}

.bg-black
{
  background-color: #000;
}

.modal,
.modal a
{
  color: #222;
}

@media (max-width: $md - 1)
{
  .sm-320-auto
  {
    width: 320px!important;
    margin: 0 auto;
  }

  .sm-350-auto
  {
    width: 350px!important;
    margin: 0 auto;
  }
}