.news
{
  h2
  {
    margin-top: 35px;
    margin-bottom: 70px;
    position: relative;

    &:after
    {
      content: '';
      display: block;
      width: 85px;
      border-bottom: 2px solid #cb0f27;
      position: absolute;
      left: -2px;
    }
  }

  &-item
  {
    p
    {
      margin-bottom: 8px;
      font-size: 16px;
    }

    &-title
    {
      display: block;
      font-size: 20px;
    }

    &-date
    {
      i
      {
        margin-right: 5px;
      }
    }

    .read-more
    {
      float: right;
    }
  }

  &-separator
  {
    border-top: 1px solid $color-hr;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.biblioteka
{
  padding-top: 20px;

  a:hover
  {
    text-decoration: none;
  }

  h4
  {
    font-size: 12px;
    color: #252525;
    font-weight: 600;
    min-height: 30px;
  }

  .data
  {
    font-size: 11px;
    color: #252525;
  }

  hr
  {
    margin: 0;
    border-top: 1px solid #919191;
  }
}