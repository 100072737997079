.product
{
  padding-top: 53px;

  h2
  {
    position: relative;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 29px;
    margin-left: 16px;
    margin-bottom: 18px;
  }

  &-menu
  {
    display: none;
    margin-top: -4px;

    @media (min-width: $lg)
    {
      display: block;
    }

    & > ul
    {
      & > li
      {
        border-top: 1px solid #848383;
        padding-top: 15px;

        &:first-child
        {
          border-top: 0;
          padding-top: 0;
        }

        & > a
        {
          text-transform: uppercase;
          font-size: 29px;
          font-weight: 300;
          margin-bottom: 14px;
          padding-left: 15px;
          display: block;
        }
      }
    }

    ul
    {
      list-style: none;
      padding-left: 0;
      margin: 0;

      ul
      {
        background-color: #000;
        padding: 10px 15px;
        margin-bottom: 20px;

        a
        {
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
  }

  &-item
  {
    background: #000;
    border: 1px solid #262626;
    padding: 5px 15px 20px;
    margin-bottom: 30px;

    @media (min-width: $md)
    {
      float: left;
      margin-right: 15px;
      margin-left: 0;
      width: 215px;
    }

    @media (min-width: $lg)
    {
      margin-right: 30px;
      width: 245px;
    }

    &-title
    {
      display: block;
      font-size: 18px;
      height: 54px;
      overflow: hidden;
    }

    &-image
    {
      text-align: center;
      margin: 0 -15px;
      height: 199px;
      overflow: hidden;
    }

    &-btn
    {
      background: $btn-product-more-bg;
      border: 0;
      color: $btn-product-more-color;
      display: block;
      text-align: center;
      font-size: 14px;
      padding: 0 0 2px;
      margin: 15px 0 6px;
      font-weight: 300;
      width: 100%;
      cursor: pointer;

      &.not-visible
      {
        visibility: hidden;
      }
    }

    &-pdf
    {
      text-align: center;

      a
      {
        font-size: 14px;
        font-weight: 300;
        display: inline-block;
        margin-left: 24px;
        border-bottom: 1px solid;
        line-height: 1.2em;

        &:hover
        {
          text-decoration: none;
        }

        &:before
        {
          content: '';
          display: block;
          background: url("../image/icon_pdf.png") no-repeat 0 0;
          width: 18px;
          height: 19px;
          position: absolute;
          margin-left: -24px;
        }
      }

      &.not-visible
      {
        visibility: hidden;
      }
    }
  }
}