$bg-primary: #000;

$color-header-line: #252525;
$color-link: #fff;
$color-hr: #252525;

$btn-product-more-bg: #252525;
$btn-product-more-color: #fff;

$xs: 0;
$sm: 480px;
$md: 768px;
$lg: 1024px;