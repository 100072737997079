body.homepage
{
  @media (min-width: $lg)
  {
    header
    {
      position: absolute;
    }
  }
}

header
{
  font-size: 16px;
  padding: 15px 0;
  z-index: 5;
  background: $bg-primary;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid $color-header-line;

  a
  {
    color: #fff;
  }

  button
  {
    display: block;

    @media (min-width: 1024px)
    {
      display: none!important;
    }
  }

  .logo
  {
    float: left;
    display: block;
    margin: 3px 0 0 5px;
  }

  .menu-languages
  {
    font-size: 22px;
    font-weight: 300;
    white-space: nowrap;
    margin-top: 14px;
    clear: both;
    display: none;

    &.show-menu
    {
      display: block;
    }

    @media (min-width: $lg)
    {
      float: right;
      font-size: 14px;
      clear: none;
      display: block;
    }

    .menu
    {
      @media (min-width: $lg)
      {
        float: left;
      }

      ul
      {
        display: none;
        padding-left: 0;
        list-style: none;
        margin: 0;

        &:first-child
        {
          display: block;
        }

        &.show-menu
        {
          display: block;
        }
      }

      &-item
      {
        margin-left: 5px;

        @media (min-width: $lg)
        {
          float: left;
          margin-left: 20px;
        }

        &-link
        {

        }
      }
    }

    .languages
    {
      float: left;
      margin-left: 20px;

      a
      {
        margin-left: 10px;
      }
    }
  }
}

.hamburger
{
  float: right;
  outline: none;

  &-inner,
  &-inner:after,
  &-inner:before
  {
    background-color: #fff;
  }
}

.hamburger.is-active:hover, .hamburger:hover
{
  opacity: 1;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner:after,
.hamburger.is-active .hamburger-inner:before
{
  background-color: #fff;
}