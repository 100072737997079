main
{
  background: $bg-primary url("../image/bg.jpg") no-repeat center bottom;

  &.has-partners
  {
    background: none;
    position: relative;

    &:after
    {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 170px;
      right: 0;
      left: 0;
      background: $bg-primary url("../image/bg.jpg") no-repeat center bottom;
      z-index: -1;
    }
  }
}

.sf-border
{
  color: #fff;

  &:first-child
  {
    .sf-wrapper
    {
      border-top: 1px solid #252525;
      padding-top: 20px;
      margin-top: 50px;
      position: relative;

      h2
      {
        position: absolute;
        top: -50px;
      }
    }
  }

  &.last
  {
    margin-bottom: 30px;

    .sf-wrapper
    {
      border-bottom: 1px solid #252525;
    }
  }

  .sf-wrapper
  {
    background: #000000;
    border-right: 1px solid #252525;
    border-left: 1px solid #252525;
  }
}

.form-contact
{
  padding: 15px;

  h3
  {
    font-size: 14px;
    font-weight: 300;
  }

  label
  {
    display: block;
    margin-bottom: 2px;
  }

  input[type=text],
  textarea
  {
    width: 100%;
    background: transparent;
    border: 1px solid #252525;
    margin-bottom: 10px;
    color: #fff;
  }

  input[type=submit],
  button[type=submit]
  {
    display: block;
    width: 100%;
    background: gray;
    border: 0;
    color: #fff;
  }
}