.images
{
  &-item
  {
    padding: 0 15px;
    margin-bottom: 30px;

    @media (min-width: $md)
    {
      float: left;
      width: 50%;
    }

    @media (min-width: $md)
    {
      float: left;
      width: 33%;
    }
  }
}